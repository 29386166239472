// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.toastr = require("toastr")
global.toastr.options = {
    "positionClass": "toast-bottom-right",
    "timeOut": "10000"
}

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

require("jquery")
require("@popperjs/core")

//require('moment/moment')
//require("moment/locale/tr")

import 'bootstrap'
import {Modal} from 'bootstrap'
import {Tooltip, Popover} from 'bootstrap'

require("fontawesome-free")
//window.moment = moment;

//require("@eonasdan/tempus-dominus")
//import { TempusDominus } from '@eonasdan/tempus-dominus';

//require("daterangepicker")

require('@kollegorna/cocoon-vanilla-js')

require('select2/dist/js/select2.full')
require('select2/dist/js/i18n/tr')

require('inputmask/dist/inputmask');

// bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css'

import './styles.scss';

window.jQuery = $;
window.$ = $;
//window.moment = moment;


// require('summernote/dist/summernote')
// require('summernote/dist/summernote-bs5');
// require('summernote/dist/lang/summernote-tr-TR');

function hideLoadingIndicator() {
    let loadingIndicator = $('#loading_indicator');
    if (loadingIndicator.length > 0 && loadingIndicator.css('display') !== 'none') {
        loadingIndicator.hide();
    }
}
function showLoadingIndicator() {
    let loadingIndicator = $('#loading_indicator');
    if (loadingIndicator.length > 0 && loadingIndicator.css('display') === 'none') {
        loadingIndicator.show();
    }
}

document.addEventListener('ajax:complete', hideLoadingIndicator);
document.addEventListener('ajax:beforeSend', showLoadingIndicator);

window.hideLoadingIndicator = hideLoadingIndicator;

$(document).on('turbolinks:load', function () {
    hideLoadingIndicator();
    //$('.date_time_picker').datetimepicker({locale: 'tr'});
    //$('.date_picker').datetimepicker({locale: 'tr', format: 'L'});

    $('form').on('submit', function() {
        let hasFileInput = $(this).find('input[type="file"]').length > 0;
        if (hasFileInput) {
            showLoadingIndicator();
        }
    });

    $('.city_select').on('change', function (e) {
        let city_id = this.value;
        if (city_id != null) {
            $.ajax({
                type: 'GET',
                url: `/counties/${city_id}`,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    let county = $('.county_select');
                    county.empty();
                    county.append('<option value>Lütfen seçiniz</option>')
                    for (let i = 0; i < data.length; i++) {
                        county.append(`<option value=${data[i][0]}>${data[i][1]}</option>`)
                    }
                }
            });
        }
    });


    $('[data-bs-toggle="tooltip"]').tooltip()
    $('[data-bs-toggle="popover"]').popover()

    $('.select2').select2({
        theme: 'bootstrap-5',
    });

    // $('.summernote').summernote({
    //     lang: 'tr-TR',
    //     height: 120,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: [
    //         ['style', ['style']],
    //         ['font', ['bold', 'underline', 'clear']],
    //         ['fontname', ['fontname']],
    //         ['color', ['color']],
    //         ['para', ['ul', 'ol', 'paragraph']],
    //         ['table', ['table']],
    //         ['insert', ['link']],
    //         ['view', ['fullscreen', 'help']],
    //     ]
    // })
    //
    // $('.summernote_medium').summernote({
    //     lang: 'tr-TR',
    //     height: 200,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: [
    //         ['style', ['style']],
    //         ['font', ['bold', 'underline', 'clear']],
    //         ['fontname', ['fontname']],
    //         ['color', ['color']],
    //         ['para', ['ul', 'ol', 'paragraph']],
    //         ['table', ['table']],
    //         ['insert', ['link']],
    //         ['view', ['fullscreen', 'help']],
    //     ]
    // })
    //
    // $('.summernote_plain').summernote({
    //     lang: 'tr-TR',
    //     height: 120,
    //     prettifyHtml: true,
    //     codeviewFilter: true,
    //     codeviewFilterRegex: /onerror=|<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
    //     codeviewIframeFilter: true,
    //     codeviewIframeWhitelistSrc: ['darphane.org.tr', 'localhost'],
    //     toolbar: []
    // })

    let finenessInput = document.getElementById('product_fineness');
    let metalSelect = document.getElementById('product_metal_id');

    let weightInput = document.getElementById('product_weight');
    let weightTypeSelect = document.getElementById('product_weight_unit');

    if (finenessInput && metalSelect) {
        function applyFinenessInputMask() {
            let symbol = metalSelect.options[metalSelect.selectedIndex].getAttribute('symbol');
            let mask = "";
            if (symbol === "AU") {
                var finenessDiv = document.getElementById("fineness-div");
                var finenessGoldDiv = document.getElementById("fineness-gold-div");
                var input1 = finenessDiv.querySelector('input');
                var input2 = finenessGoldDiv.querySelector('select');

                if (finenessDiv) {
                    finenessDiv.style.display = "none";
                    input1.disabled = true;
                }
                if (finenessGoldDiv) {
                    finenessGoldDiv.style.display = "block";
                    input2.disabled = false;
                }
                //mask = "999{1,2}";
                //document.getElementById('fineness-span').innerHTML = "995 ve 1000 arasında olmalı ve tam sayı girilmeli"
            } else {
                var finenessDiv = document.getElementById("fineness-div");
                var finenessGoldDiv = document.getElementById("fineness-gold-div");
                var input1 = finenessDiv.querySelector('input');
                var input2 = finenessGoldDiv.querySelector('select');

                if (finenessGoldDiv) {
                    finenessGoldDiv.style.display = "none";
                    input2.disabled = true;
                }
                if (finenessDiv) {
                    finenessDiv.style.display = "block";
                    input1.disabled = false;
                }
                mask = "99{1,2}.99";
                const decimalPartLength = (finenessInput.value.split('.')[1] || '').length;
                if (decimalPartLength === 1) {
                    finenessInput.value += '0';
                }
                if (symbol === "AG") {
                    document.getElementById('fineness-span').innerHTML = "99.90 ve 100.00 arasında olmalı"
                } else if (symbol === "PT" || symbol === "PD") {
                    document.getElementById('fineness-span').innerHTML = "99.95 ve 100.00 arasında olmalı"
                }

                Inputmask({
                    mask: mask,
                    greedy: false,
                    definitions: {
                        "9": {
                            validator: function (chrs, buffer, pos, strict, opts) {
                                if (pos === 0 && chrs === '0') {
                                    return false;
                                } else {
                                    return /\d/.test(chrs);
                                }
                            }
                        }
                    },
                    oncomplete: function (event) {
                        const numericValue = parseFloat(event.target.value);
                        let min;
                        let max;
                        if (symbol === "AU") {
                            min = 995;
                            max = 1000;
                        } else if (symbol === "AG") {
                            min = 99.90;
                            max = 100.00;
                        } else {
                            min = 99.95;
                            max = 100.00;
                        }
                        if (numericValue < min || numericValue > max) {
                            event.target.value = Math.min(Math.max(numericValue, min), max);
                        }
                    },
                    clearIncomplete: true,
                    showMaskOnFocus: false,
                    showMaskOnHover: false
                }).mask(finenessInput);
            }
        }

        if (metalSelect.options[metalSelect.selectedIndex].getAttribute('symbol') === 'AU') {
            finenessInput.value = parseInt(finenessInput.value, 10);
        }

        applyFinenessInputMask();
        metalSelect.addEventListener('change', applyFinenessInputMask);
    }

    if (weightInput && weightTypeSelect) {
        function applyWeightInputMask() {
            let selectedUnit = weightTypeSelect.value;
            let mask = "";
            if (selectedUnit === "0") { // The value '0' represents 'gr'
                mask = "9{1,3}[.9]";
                document.getElementById('weight-span').innerHTML = "1 ve 999.9 arasında olmalı"
            } else if (selectedUnit === "2") { // The value '2' represents 'ons'
                mask = "9{1,3}";
                document.getElementById('weight-span').innerHTML = "1 ve 100 arasında olmalı ve tam sayı girilmeli"
            } else if (selectedUnit === "1") { // The value '1' represents 'kg'
                mask = "9{1,3}[.9]";
                document.getElementById('weight-span').innerHTML = "1 ve 100 arasında olmalı"
            }

            Inputmask({
                mask: mask,
                greedy: false,
                definitions: {
                    "9": {
                        validator: function (chrs, buffer, pos, strict, opts) {
                            if (pos === 0 && chrs === '0') {
                                return false;
                            } else {
                                return /\d/.test(chrs);
                            }
                        }
                    }
                },
                oncomplete: function (event) {
                    const numericValue = parseFloat(event.target.value);
                    let value = (selectedUnit === "0") ? 999.9 : 100;
                    if (numericValue > value) {
                        event.target.value = value;
                    }
                },
                clearIncomplete: true,
                showMaskOnFocus: false,
                showMaskOnHover: false
            }).mask(weightInput);
        }

        if (weightTypeSelect.value === '2') { // ons
            weightInput.value = parseInt(weightInput.value, 10);
        }

        applyWeightInputMask();
        weightTypeSelect.addEventListener('change', applyWeightInputMask);
    }

    var phoneInput = document.getElementById('user_phone_attributes_number');
    if (phoneInput) {
        Inputmask({"mask": "0599-999-99-99"}).mask(phoneInput);
    }

    var settingPricePerProduct = document.getElementById('setting_price_per_product');
    if (settingPricePerProduct) {
        Inputmask({
            alias: 'numeric',
            min: 1,
            allowMinus: false,
            digits: 2, // Number of decimal places
            digitsOptional: false, // Ensures decimal part is mandatory
            radixPoint: ".", // Decimal separator
            groupSeparator: ",", // Thousand separator
            autoGroup: true, // Automatically adds thousand separators
            rightAlign: false
        }).mask(settingPricePerProduct);
    }

});
